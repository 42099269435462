<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/courses">Menu</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view />
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Peter's Cookout Diner";
      },
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Yanone+Kaffeesatz:wght@200&display=swap");

$sign-color: #00ffff;
$sign-color-alt: #42b983;
$diner-color: #ff0000;
$shadow-color: #2c3e50;
$shadow-color-light: #42b983;
$background-color: #f1c40f;
// $background-color: #f1c40f;

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: $background-color;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  font-family: "Dancing Script", cursive;
  font-size: 2rem;
  padding-top: 2rem;
  background-color: $background-color;
  text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
    3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    color: $sign-color-alt;

    &.router-link-exact-active {
      color: $sign-color;
    }
  }
}
</style>
